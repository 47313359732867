@font-face {
  font-family: 'OpenSans-Light';
  src: local('OpenSans-Light'), url(./fonts/OpenSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'), url(./fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Italic';
  src: local('OpenSans-Italic'), url(./fonts/OpenSans-LightItalic.ttf) format('truetype');
}