.mymodal {
  width: clamp(50%, 900px, 90%) !important;
  margin: auto;
  padding: 0 2rem;
  border-radius: 12px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: fixed;
  max-height: 90%;
}

.mymodal-background {
  background: #181818;
  border-radius: 12px;
}

.title {
  color: white;
  font-size: 25pt;
  font-family: "OpenSans-Bold";
  text-align: center;
}

.back-button {
  color: rgba(255, 255, 255, 0.7);
  font-size: 22pt;
  font-family: "OpenSans-Light";
  text-align: left;
}

.back-button:hover {
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

.header-row {
  width: 100% !important;
  border-bottom: 1px solid rgba(80, 80, 80, 0.7);
  padding: 5px 0px 5px 0px;
}

.title-row {
  width: 100% !important;
}

.thumb-class {
  padding: 20px;
  width: 480px;
  height: 270px;
  border-radius: 5px;
}

.body-text {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-family: "OpenSans-Light";
  overflow: scroll;
}

.video-link {
  font-size: 18pt;
}

a {
  color: rgba(255, 255, 255, 0.6) !important;
  transition: all 0.3s;
  border: none !important;
}
a:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}

.description {
  padding: 15px 0px 20px 0px;
  font-size: 15pt;
  overflow-y: auto;
}

@media only screen and (max-width: 800px) {
  .thumb-class {
    width: 360px;
    height: 202.5px;
  }
  .title {
    font-size: 20pt;
  }
  .description {
    font-size: 13pt;
  }
  a {
    font-size: 14pt;
  }
}

@media only screen and (max-width: 650px) {
  .description {
    font-size: 10pt;
  }
  .title {
    font-size: 16pt;
  }
  a {
    font-size: 12pt;
  }
}

@media only screen and (max-height: 900px) {
  .description {
    font-size: 13pt;
  }
}
