.skills-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 7vh;
  padding-right: 15vw;
  padding-left: 15vw;
}

.skill-card-container {
  margin: 3vh 2vw; 
  width: 150px;
}

.spacer {
  background-position: center;
  background-size: cover;
}

.no-margin {
  margin: 0px !important;
  padding: 0px !important;
}

.skills-background {
  position: absolute;
}