.card-container {
  display: flex;
  width: 80% !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin-top: 25px;
  transition: all 0.3s;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0);
  cursor: pointer;
}

.card-container:hover {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.1);
}

.title-row {
  font-family: "OpenSans-Bold";
  color: white;
  font-size: 22pt;
  text-align: left;
}

.summary-row {
  font-family: "OpenSans-Light";
  color: #cecece;
  font-size: 13pt;
  text-align: left;
  padding-top: 10px;
}

.image-class {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 1400px) {
  .card-container {
    display: inline-block;
  }
  .image-class {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .summary-row {
    font-size: 10pt;
  }
  .title-row {
    font-size: 15pt;
  }
}
