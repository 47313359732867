.background-shadow {
  box-shadow: inset 0px 10px 140px 45px rgba(0, 0, 0, 1);
}

.background-position {
  background-attachment: fixed !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 900ms ease-in;
  animation-duration: 800ms;
  animation-name: slidein;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
  animation-duration: 500ms;
  animation-name: slideout;
}

@keyframes slidein {
  from {
    transform: translateX(1000px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideout {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1000px);
  }
}
