.skill-image {
  width: 80px;
  height: 80px;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.card-box {
  color: rgba(247, 250, 255, 0.9);
  font-family: "OpenSans-Bold";
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill-container {
  width: 120px;
  height: 120px;
  background-color: rgba(0, 183, 255, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.skill-container:hover {
  width: 130px;
  height: 130px;
}

.skill-container:hover .skill-image {
  width: 90px;
  height: 90px;
}

.skill-text-container {
  font-size: 14pt;
  align-items: flex-end;
  margin-top: 10px;
}