h1 {
  color: white;
}

.position {
  position: absolute;
}

.remark {
  font-family: "OpenSans-Italic";
  font-size: 18pt;
  color: rgba(255, 255, 255, 0.4);
  padding-bottom: 10px;
}

.divider {
  margin-top: 20px !important;
  height: 1px;
  background: white;
  opacity: 0.6;
  animation-name: spreadout;
  animation-delay: 300ms;
  animation-duration: 900ms;
  justify-content: center;
}

.footer {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}

.footer-link {
  font-family: "OpenSans-Light";
  color: rgba(255, 255, 255, 0.8);
  font-size: 18pt;
}
.footer-link:hover {
  color: rgba(255, 255, 255, 1);
}

@keyframes spreadout {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
